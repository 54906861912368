//import Headline from "global/Headline";

import React, { useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux';
import Layout from 'templates/Layout';

import Link from 'components/Link'

const SEARCH_URL = `${process.env.GATSBY_ZMS_API_URL}search/all/`;

var canUseDOM = !!(
	(typeof window !== 'undefined' &&
		window.document && window.document.createElement)
);

const SearchPage = (props) => {
	const dispatch = useDispatch();
	const searchTerms = useSelector((state) => state.SearchReducer.searchTerms);
	const page = useSelector((state) => state.SearchReducer.page);
	const searchData = useSelector((state) => state.SearchReducer.searchData);
	const searchLoading = useSelector((state) => state.SearchReducer.searchLoading);
	// const searchFailed = useSelector((state) => state.SearchReducer.searchFailed);
	const { location } = props;
	let types = {
		Page: {
			cleanType: 'Page',
		},
		Release: {
			cleanType: 'Press Release',
		},
	};
	let terms = searchTerms;
	if (location.search !== '') {
		let split = location.search.substr(1).split('=');
		split.shift()
		terms = split.join('=');
		terms = decodeURIComponent(terms).replace(/\+/g, ' ');
	}

	const pageSize = 10;
	let urlString = `${SEARCH_URL}?terms=${terms}&_page=${page}&_pageSize=${pageSize}&_lang=en`;
	useEffect(() => {
		const retrieveSearch = async () => {
			try {
				const response = (await fetch(urlString));
				const items = (await response.json());
				dispatch({
					type: 'SET_SEARCH_DATA',
					total: items.total,
					data: items.data,
					pages: items.pages,
				});
				dispatch({
					type: 'SET_SEARCH_TERMS',
					searchTerms: terms,
				});
			} catch (err) {
				dispatch({
					type: 'SET_SEARCH_FAILURE',
					failed: true,
				});
			} finally {
				dispatch({
					type: 'SET_SEARCH_LOADING',
					loading: false,
				});
			}
		};
		retrieveSearch();
	}, [urlString, dispatch, terms]);

	const Pager = (props) => {
		let pages = [];
		if (props.currentPage > 1) { // Show previous button if not on first page
			pages.push(<button key={`page_prev`} className='button' onClick={() => dispatch({ type: 'SET_PAGE', page: props.currentPage - 1 })}>← Prev</button>);
		}
		if (props.searchData.pages > 1) {
			// Determine the start and end of the pagination
			let startPage = props.currentPage - 5;
			let endPage = props.currentPage + 4;
			let loopStart = 1;
			let loopEnd = props.searchData.pages;
			if (startPage > 0) loopStart = startPage; // check for positive value to set the start of pagination
			if (startPage > 0 && endPage >= props.searchData.pages) { // check if last page is reached in paginator
				if (startPage >= 10) {
					loopStart = props.searchData.pages - 9;
				} else {
					loopStart = startPage;
				}
				loopEnd = props.searchData.pages;
			} else if (startPage > 0 && endPage < props.searchData.pages) { // otherwise set the last page based off of the current page
				loopEnd = endPage;
			} else if (props.searchData.pages < 10) {
				loopEnd = props.searchData.pages;
			} else if (endPage < 10) {
				loopEnd = 10;
			}
			for (let i = loopStart; i <= loopEnd; i++) { // create the buttons for the pagination
				pages.push(<button className={(i === props.currentPage ? 'current' : '')} key={`page_${i}`} onClick={() => dispatch({ type: 'SET_PAGE', page: i })}>{i}</button>);
			}
		}
		if (props.currentPage < props.searchData.pages) { // Show next button if not on last page
			pages.push(<button key={`page_next`} className='button' onClick={() => dispatch({ type: 'SET_PAGE', page: props.currentPage + 1 })}>Next →</button>);
		}
		if (pages.length > 0) return (<div className="grid-container"><div className="grid-x"><div className="small-12 cell"><div className="paginator">{pages}</div></div></div></div>);
		return (<div />);
	}

	function limit(string = '', limit = 0) {
		return string.split(' ', limit).join(' ') + '...';
	}
	return (
		<Layout>
			{/*<Headline title="Search" bgColor="#1093CE" type="bg-color" isPageHeader={true} />*/}
			<section id="search-hero" className="hero-header-hero" style={{ marginTop: "2.75rem" }}>
				<div className="flenter">
					<div className="grid-container">
						<div className="grid-x title-area">
							<div className="small-12 medium-6 cell">
								<h3>Search Results for "{terms}"</h3>
								<p>Showing <strong>{!searchData.data?.length ? '0' : (page * pageSize - pageSize) + 1} - {(page * pageSize < searchData.total) ? page * pageSize : searchData.total}</strong> out of <strong>{searchData.total}</strong> results.</p>
							</div>
							<div className="small-12 medium-6 cell" style={{ textAlign: 'right', display: 'inline-block' }}>
								<label className="sr-only" htmlFor="search">Search the Site</label>
								<form action="/search/" className="closed" id="search-form">
									<input id="search" name="keywords" className="closed" type="text" placeholder="Beaches, hotels..." />
									<input className="search-button button" type="Submit" text="Search" />
								</form>
							</div>
						</div>
					</div>
					<section id="search-results">
						<div className="grid-container">
							<div className="grid-x">
								<div className="small-12 cell">
									{searchData.data?.filter(it => !!it.uri).map((item, key) => {
										if (canUseDOM) {
											const div = document.createElement("div");
											div.innerHTML = item.content;
											const text = div.textContent || div.innerText || "";
											item.content = text;
										}
										if (searchLoading) {
											return 'Loading';
										}
										else {
											return (
												<div key={key} className="results-card float-me">
													<Link href={item.uri}>
														<div className="grid-x">
															<div className="small-12 cell">
																{types.hasOwnProperty(item._model) && <div className="small">{types[item._model]['cleanType']}</div>}
																<h5 className="lower">{item.title}</h5>
																<p>{limit(item.content.replaceAll("|", ""), 42)}</p>
															</div>
														</div>
													</Link>
												</div>
											)
										}
									})}
								</div>
							</div>
						</div>
					</section>
				</div>
				{searchData.pages > 0 &&
					<Pager searchData={searchData} currentPage={page} />
				}
			</section>
		</Layout>
	)
}

export default SearchPage

export { Head } from 'components/templates/Head';